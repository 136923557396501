exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-princess-tsx": () => import("./../../../src/pages/princess.tsx" /* webpackChunkName: "component---src-pages-princess-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-blog-article-template-tsx": () => import("./../../../src/templates/blog-article-template.tsx" /* webpackChunkName: "component---src-templates-blog-article-template-tsx" */),
  "component---src-templates-blog-page-template-tsx": () => import("./../../../src/templates/blog-page-template.tsx" /* webpackChunkName: "component---src-templates-blog-page-template-tsx" */),
  "component---src-templates-general-page-template-tsx": () => import("./../../../src/templates/general-page-template.tsx" /* webpackChunkName: "component---src-templates-general-page-template-tsx" */),
  "component---src-templates-slot-page-template-tsx": () => import("./../../../src/templates/slot-page-template.tsx" /* webpackChunkName: "component---src-templates-slot-page-template-tsx" */)
}

